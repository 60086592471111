import React from 'react';
import { Link } from 'react-router-dom';


function AddVideo(){
		
	var home_link = '/home';
	var profile_link = '/member/profile';
	var videos_link = '/member/videos';
	var add_video_link = '/member/add_video';
	var DashboardLinks = (
		<div>
		<br/>
		<Link to={home_link} className="font-raleway font-weight-600">Go to Home</Link>
		<br/><br/>
		<Link to={profile_link} className="font-raleway font-weight-600">Go to Profile</Link>
		<br/><br/>
		<Link to={videos_link} className="font-raleway font-weight-600">Go to Videos</Link>
		<br/><br/>
		<Link to={add_video_link} className="font-raleway font-weight-600">Go to Add Video</Link>
		</div>
	)
    
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-40">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway page-header font-weight-800 italic txt-dark-blue bb2-dark-blue uppercase pb-5">Dashboard</div>
					</div>
					<div className="large-12 medium-12 small-12 cell text-left pt-20">
						{DashboardLinks}
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddVideo;