
import Home from './components/frontend/Home';
import About from './components/frontend/About';
import Contact from './components/frontend/Contact';
import Help from './components/frontend/Help';
import SearchVideos from './components/frontend/SearchVideos';

import Login from './components/frontend/auth/Login';
import Register from './components/frontend/auth/Register';
import ActivateAccount from './components/frontend/auth/ActivateAccount';
import ForgotPassword from './components/frontend/auth/ForgotPassword';
import ResetPassword from './components/frontend/auth/ResetPassword';

import MemberDashboard from './components/member/Dashboard';
import MemberProfile from './components/member/Profile';
import MemberVideos from './components/member/Videos';
import MemberAddVideo from './components/member/AddVideo';

import AdminDashboard from './components/admin/Dashboard';
import AdminProfile from './components/admin/Profile';
import AdminUsers from './components/admin/Users';
import AdminCategories from './components/admin/Categories';
import AdminAddCategory from './components/admin/AddCategory';

const ActivateAccountWithId = ({ id }) => <ActivateAccount id={id} />;
const ResetPasswordWithIdEmail =  ({ id, email }) => <ResetPassword id={id} email={email} />;

const routes = [
	{
		path: '/',
		element: <Home />,
		type: 'public',
	}, {
		path: '/home',
		element: <Home />,
		type: 'public',
	}, {
		path: '/about',
		element: <About />,
		type: 'public',
	}, {
		path: '/contact',
		element: <Contact />,
		type: 'public',
	}, {
		path: '/help',
		element: <Help />,
		type: 'public',
	}, {
		path: '/videos',
		element: <SearchVideos />,
		type: 'public',
	}, {
		path: '/login',
		element: <Login />,
		type: 'public',
	}, {
		path: '/register',
		element: <Register />,
		type: 'public',
	}, {
		path: '/activate_account/:id',
		element: <ActivateAccountWithId />,
		type: 'public',
	}, {
		path: '/forgot_password',
		element: <ForgotPassword />,
		type: 'public',
	}, {
		path: '/reset_password/:id/:email',
		element: <ResetPasswordWithIdEmail />,
		type: 'public',
	}, {
		path: '/member/dashboard',
		element: <MemberDashboard />,
		type: 'private member',
	}, {
		path: '/member/profile',
		element: <MemberProfile />,
		type: 'private member',
	}, {
		path: '/member/videos',
		element: <MemberVideos />,
		type: 'private member',
	}, {
		path: '/member/add_video',
		element: <MemberAddVideo />,
		type: 'private member',
	}, {
		path: '/admin/dashboard',
		element: <AdminDashboard />,
		type: 'private admin',
	}, {
		path: '/admin/profile',
		element: <AdminProfile />,
		type: 'private admin',
	}, {
		path: '/admin/users',
		element: <AdminUsers />,
		type: 'private admin',
	}, {
		path: '/admin/categories',
		element: <AdminCategories />,
		type: 'private admin',
	}, {
		path: '/admin/add_category',
		element: <AdminAddCategory />,
		type: 'private admin',
	},
	// And so on.
];

export default routes;