import React, {useState, useEffect, Fragment} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';
import Modal from '../../layouts/frontend/Modal';

import Alert from "@material-ui/lab/Alert";
import {Snackbar} from "@material-ui/core";

import axios from 'axios';
import swal from 'sweetalert';

import $ from 'jquery'; // Import jQuery

import video_icon from '../../assets/frontend/images/video_icon_white.png';

const aws_s3_url = `https://video-app-s3-bucket.s3.us-west-1.amazonaws.com`;
const per_page = 10;
	
function Videos(){
	
	const navHistory = useNavigate();
  
    // Initial states
    const [isLoading, setIsLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false);
    const [openAlertDeleted, setOpenAlertDeleted] = useState(false);
	const [videoList, setVideoList] = useState([
        //{ id: '', category_id: "", title: "", description: "", privacy: "", audience: "", video_file: "", thumbnail_file: "", status: "", random_id: "", created: "", updated: "" },
	]);
	const [videoListMessage, setVideoListMessage] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [lastPage, setLastPage] = useState(1);
	const [nextPage, setNextPage] = useState(null);
	const [prevPage, setPrevPage] = useState(null);
	
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [isModalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState('');
		
	// Remove the Foundation modal directly
		    
	// Initial call for user list items
	useEffect(() => {

		setIsLoading(true);
		
		if (isMounted) {
    		$(document).foundation();
    		
		    // Close the Foundation modal directly
			setModalContent('');
			setModalOpen(false);
			
			console.log('[Member Videos - useEffect] mounted');
			
			var page = 1;
			getVideos(page);

		}else {
	      setIsMounted(true);
	    }
		
	}, [isMounted]);
	
	function getVideos(page){
		
		axios.get('/sanctum/csrf-cookie').then(response1 => {// CSRF Protection through Laravel
			axios.get('/api/read_videos', {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
				},
			    params: {
			        per_page: per_page, // Set your preferred number of items per page
			        page: page, // Specify the page number you want to fetch
			    }
			}).then(response2 =>{
				if(response2.data.status === 200){//HTTP_OK
					
					//set data
					if(response2.data.videos && response2.data.videos.length > 0){
						setVideoList(response2.data.videos);
						setCurrentPage(response2.data.pagination_data.current_page);
						setLastPage(response2.data.pagination_data.last_page);
						setNextPage(response2.data.pagination_data.next_page);
						setPrevPage(response2.data.pagination_data.prev_page);
						setVideoListMessage(null);
					}else{
						setVideoList([]);
						setCurrentPage(null);
						setLastPage(null);
						setNextPage(null);
						setPrevPage(null);
						setVideoListMessage('No Videos Exists');
					}
						
	            }else if(response2.data.status === 401){//HTTP_UNAUTHORIZED
	            
					//user not authenticated on server so remove from local storage
					AuthUtility.clearAuthData();
	            
					swal("Warning",response2.data.message,"warning");
	                	
					navHistory('/login');
					
	            }else if(response2.data.status === 422){//HTTP_UNPROCESSABLE_ENTITY
					
					//set data
					if(response2.data.videos && response2.data.videos.length > 0){
						setVideoList(response2.data.videos);
						setCurrentPage(response2.data.pagination_data.current_page);
						setLastPage(response2.data.pagination_data.last_page);
						setNextPage(response2.data.pagination_data.next_page);
						setPrevPage(response2.data.pagination_data.prev_page);
						setVideoListMessage(null);
					}else{
						setVideoList([]);
						setCurrentPage(null);
						setLastPage(null);
						setNextPage(null);
						setPrevPage(null);
						setVideoListMessage('No Videos Exists');
					}
	            }else{//more errors
	            }
		
				setIsLoading(false);
				
			}).catch(function (error) {
				console.log('getVideos error: ',error + ' back-end api call error');
				
				setIsLoading(false);
			});

		}).catch(function (error) {
			console.log('getVideos error: ',error + ' csrf-cookie is outdated');
		
			setIsLoading(false);
            
			//user not authenticated on server so remove from local storage
			
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
			
			navHistory('/login');
		});
	}
	
	
	const handleOpenVideoView = (event, video) => {
		event.preventDefault();
		
		setModalContent('');
		setModalOpen(false);
		
		const video_url = aws_s3_url + '/' + video.video_url;
		
		//alert('Video View Coming Soon');
		
    	//setSelectedVideo(video);
    	setTimeout(() => {
			const htmlContent = (
			<div className="grid-x">
				<div className="large-12 medium-12 small-12 cell text-center p10">
					<div className="font-source-sans font-large font-weight-600 txt-000 left">{video.title}</div>
					<div className="video-container pt-5">
						<video controls>
						<source src={video_url} type="video/mp4" />
						Your browser does not support the video tag.
						</video>
					</div>
	
				</div>
			</div>
			);
			
			setModalContent(htmlContent);
			setModalOpen(true);
			
		    // Open the Foundation modal directly
		    $('#generic_modal').foundation('open');
	    }, 250);
	};
	
	const handleVideoEditClick = (video) => {
    	alert('Video Editing Coming Soon.');
	};
	
    // Function For closing the alert snackbar
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        
        setOpenAlertDeleted(false);
    };
    
    // Delete row of id:i
    const handleRemoveClick = (i) => {
        const list = [...videoList];

	    // If the user confirms, proceed with the deletion
		if(list[i]['random_id'] && list[i]['random_id'] !== ''){

			var video_title = list[i]['title'];
	    	// Show a confirmation dialog
	    	const isConfirmed = window.confirm('Are you sure you want to delete the video '+video_title+'?');
			if(isConfirmed){
				deleteVideoFromDB(list[i]['random_id']);//send a specific unique ID to delete
			}
		}
    };

	function deleteVideoFromDB(video_random_id){
		
		setIsLoading(true);
			
		//values sent to api for an individual check list item delete
		var payload;
		if(video_random_id && (video_random_id !== '' || video_random_id !== ' ')){
			payload = {
				video_random_id: video_random_id,
			}
		}else{
			return;
		}
		
		axios.get('/sanctum/csrf-cookie').then(response1 => {// CSRF Protection through Laravel
			axios.post('/api/delete_video', payload, {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('auth_token')}`
				}
			}).then(response2 =>{
				if(response2.data.status === 200){//HTTP_OK
				
					//TODO: update all state properties
						
						//set data
						if(response2.data.videos && response2.data.videos.length > 0){
							setVideoList(response2.data.videos);
							setVideoListMessage(null);
						}else{
							setVideoList([]);
							setVideoListMessage('No Videos Exists');
						}
						
	            }else if(response2.data.status === 401){//HTTP_UNAUTHORIZED
	            
					//user not authenticated on server so remove from local storage
	                localStorage.removeItem('auth_token');
	                localStorage.removeItem('auth_role');
	
					if(!localStorage.getItem('remember_me') || localStorage.getItem('remember_me') !== 'true'){
	                	localStorage.removeItem('auth_users_name');
	            		localStorage.removeItem('auth_users_last_name');
	                	localStorage.removeItem('auth_email');
	                	localStorage.removeItem('password');
					}
	            
					swal("Warning",response2.data.message,"warning");
	                	
					navHistory('/login');
					
	            }else if(response2.data.status === 422){//HTTP_UNPROCESSABLE_ENTITY
						
					//set data
					if(response2.data.videos && response2.data.videos.length > 0){
						setVideoList(response2.data.videos);
						setVideoListMessage(null);
					}else{
						setVideoList([]);
						setVideoListMessage('No Videos Exists');
					}
	            }else{//more errors
	            }
	            
				setIsLoading(false);
		
			}).catch(function (error) {
				console.log('deleteVideoFromDB error: ',error + ' back-end api call error');
			});

		}).catch(function (error) {
			console.log('deleteVideoFromDB error: ',error + ' csrf-cookie is outdated');
			
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
	                	
			setIsLoading(false);
			
			navHistory('/login');
		
		});
	}

  	return (
		<div className="body-content bg-fff pt-70l-110m-50s">
		
			<Snackbar open={openAlertDeleted} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} style={{ marginTop: "70px" }}>
				<Alert onClose={handleClose} severity="error">Video deleted successfully!</Alert>
			</Snackbar>
			
			<div className="panel large ptb-20l-10s">
			
				<div className="grid-x">
					<div className="large-11 medium-10 small-9 cell text-left">
						<div className="font-raleway page-header font-weight-800 italic txt-dark-blue bb2-dark-blue uppercase pb-5">Videos</div>
					</div>
					<div className="large-1 medium-2 small-3 cell text-right">
						<Link to="/member/add_video" className="button tiny">
							<img src={video_icon} className="" width="25" alt="add video"/>
						</Link>
					</div>
				</div>
				
				{videoList.length > 0  ? (
					<>
				<div className="grid-x bg-ddd ptb-5 mt-5">
					<div className="large-2 medium-2 small-4 cell p-5">
						<div className="font-source-sans font-standard font-weight-600 txt-000 text-left">Video</div>
					</div>
					<div className="large-4 medium-4 small-0 cell p-5 hide-for-small-only">
						<div className="font-source-sans font-standard font-weight-600 txt-000 text-left">Description</div>
					</div>
					<div className="large-2 medium-2 small-0 cell p-5 hide-for-small-only">
						<div className="font-source-sans font-standard font-weight-600 txt-000 text-center">Categories</div>
					</div>
					<div className="large-2 medium-2 small-4 cell p-5">
						<div className="font-source-sans font-standard font-weight-600 txt-000 text-center">Visibility</div>
					</div>
					<div className="large-2 medium-2 small-4 cell p-5">
						<div className="font-source-sans font-standard font-weight-600 txt-000 text-center">Date</div>
					</div>
				</div>
				<div id="video_list" className="grid-x">
	        	{videoList.map((video, i) => (
					<>
						<div key={'no_edit_1_'+video.id+video.random_id} className={i % 2 === 0 ? 'large-2 medium-2 small-4 cell bg-fff p-5 text-left' : 'large-2 medium-2 small-4 cell bg-eee p-5 text-left'}>
							<img src={aws_s3_url + '/' + video.thumbnail_url} alt="Video thumbnail"  />
						</div>
						<div key={'no_edit_2_'+video.id+video.random_id} className={i % 2 === 0 ? 'large-4 medium-4 small-0 cell bg-fff p-5 text-left hide-for-small-only' : 'large-4 medium-4 small-0 cell bg-eee p-5 text-left hide-for-small-only'}>
							<div className="font-source-sans font-standard font-weight-500 txt-333" valign="top">
								<span className="font-source-sans font-medium font-weight-600 hide-for-small-only">{video.title}</span>
								<div className="hide-for-720px">
									<div className="font-standard txt-777 pt-5">{video.description}&nbsp;&nbsp;<b>{video.views}&nbsp;&nbsp;views</b></div>
								</div>
								<div className="hide-for-720px">
									<div className="pt-10">
										<Link onClick={(e) => handleOpenVideoView(e, video)} className="button tiny uppercase" valign="top">view</Link>
										&nbsp;
										&nbsp;
										&nbsp;
										<Link onClick={() => handleVideoEditClick(video)} className="button tiny uppercase" valign="top">edit</Link>
										&nbsp;
										&nbsp;
										&nbsp;
										<Link onClick={() => handleRemoveClick(i)} onTouchEnd={() => handleRemoveClick(i)} className="button tiny uppercase" valign="top">delete</Link>
									</div>
								</div>
							</div>
						</div>
						<div key={'no_edit_3_'+video.id+video.random_id} className={i % 2 === 0 ? 'large-2 medium-2 small-0 cell bg-fff p-5 text-center hide-for-small-only' : 'large-2 medium-2 small-0 cell bg-eee p-5 text-center hide-for-small-only'}>
							<div className="font-source-sans font-standard font-weight-500 txt-333" valign="top">
							{explodeCategories(video.categories)}
							</div>
						</div>
						<div key={'no_edit_4_'+video.id+video.random_id} className={i % 2 === 0 ? 'large-2 medium-2 small-4 cell bg-fff p-5 text-center' : 'large-2 medium-2 small-4 cell bg-eee p-5 text-center'}>
							<div className="font-source-sans font-standard font-weight-500 txt-333" valign="top">
							Privacy<div className="font-standard txt-777 ptb-5">{video.privacy}</div>
							Audience<div className="font-standard txt-777 pt-5">{video.audience}</div>
							</div>
						</div>
						<div key={'no_edit_5_'+video.id+video.random_id} className={i % 2 === 0 ? 'large-2 medium-2 small-4 cell bg-fff p-5 text-center' : 'large-2 medium-2 small-4 cell bg-eee p-5 text-center'}>
							<div className="font-source-sans font-standard font-weight-500 txt-333" valign="top">
							Created<div className="font-standard txt-777 ptb-5">{convertDateTimeToText(video.created_at)}</div>
							Updated<div className="font-standard txt-777 pt-5">{convertDateTimeToText(video.updated_at)}</div>
							</div>
						</div>
						<div key={'no_edit_6_'+video.id+video.random_id} className={i % 2 === 0 ? 'large-0 medium-0 small-12 cell bg-fff p-5 pb-10 text-left show-for-720px' : 'large-0 medium-0 small-12 cell bg-eee p-5 pb-10 text-left show-for-720px'}>
							<div className="font-source-sans font-medium font-weight-600 pb-5 show-for-small-only">{video.title}</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333" valign="top">
								{video.description}&nbsp;&nbsp;<b>{video.views}&nbsp;&nbsp;views</b>
							</div>
							<div className="font-source-sans font-standard font-weight-500 txt-333 pt-10 show-for-small-only" valign="top">
							{explodeCategories2(video.categories)}
							</div>
							<div className="pt-10">
								<Link onClick={(e) => handleOpenVideoView(e, video)} className="button tiny uppercase left" valign="top">view</Link>
								<Link onClick={() => handleRemoveClick(i)} onTouchEnd={() => handleRemoveClick(i)} className="button tiny uppercase right" valign="top">delete</Link>
								<span className="right">
								&nbsp;
								&nbsp;
								&nbsp;
								&nbsp;
								&nbsp;
								</span>
								<Link onClick={() => handleVideoEditClick(video)} className="button tiny uppercase right" valign="top">edit</Link>
							</div>
						</div>
					</>
				))}
				</div>
				<div className="grid-x">
					<div id="video_list_pagination" className="large-12 medium-12 small-12 cell pt-10">
						{/* Pagination links */}
						{lastPage > 1 && (
						<ul className="pagination">
							{prevPage && (
							<li className="page-item">
							<Link className="page-link" onClick={() => getVideos(currentPage - 1)}>Previous</Link>
							</li>
							)}
							
							{[...Array(lastPage).keys()].map(page => (
							<li key={page + 1} className={`page-item ${page + 1 === currentPage ? 'active' : ''}`}>
							<Link className="page-link" onClick={() => getVideos(page + 1)}>{page + 1}</Link>
							</li>
							))}
							
							{nextPage && (
							<li className="page-item">
							<Link className="page-link" onClick={() => getVideos(currentPage + 1)}>Next</Link>
							</li>
							)}
						</ul>
						)}
					</div>
				</div>
				</>
				) : (
				<div className="grid-x">
					<div id="video_list" className="large-12 medium-12 small-12 cell pt-5 font-source-sans font-medium">{videoListMessage}</div>
				</div>
				)}
				
				{isLoading && 
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-center">
						<LoadingSpinner paddingClass="p-20l-10s" />
					</div>
				</div>
				}
				
			</div>
      
			{/* Render the Modal component */}
			<Modal isOpen={isModalOpen} htmlContent={modalContent}></Modal>
      
		</div>
	);
}

export default Videos;

const categories = [
  { id: 1, title: 'Category 1' },
  { id: 2, title: 'Category 2' },
  { id: 3, title: 'Category 3' },
  { id: 4, title: 'Category 4' },
  { id: 5, title: 'Category 5' },
  { id: 6, title: 'Category 6' },
  // Add more categories as needed
];

function explodeCategories(categoryIds){
	  const categoryIdArray = categoryIds.split(';').map(Number); // Convert string IDs to numbers
  const selectedCategories = categories.filter(category => categoryIdArray.includes(category.id));

  return (
    <div>
      {selectedCategories.map(category => (
        <div className="font-source-sans font-standard txt-777 pb-5" key={category.id}>{category.title}</div>
      ))}
    </div>
  );
}

function explodeCategories2(categoryIds){
	  const categoryIdArray = categoryIds.split(';').map(Number); // Convert string IDs to numbers
  const selectedCategories = categories.filter(category => categoryIdArray.includes(category.id));

  return (
    <span><u>Categories:</u>&nbsp;
      {selectedCategories.map((category, index) => (
        <span className="txt-777">{category.title}{index + 1 === selectedCategories.length ? '' : ', '}</span>
      ))}
    </span>
  );
}

function convertDateTimeToText(some_date_time){
	
	if(!some_date_time || some_date_time === ''){
		return '';
	}else{
		var date = new Date(some_date_time);
		
	    var day = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
	    var month = (date.getMonth()+1) < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
		var year = date.getFullYear();
	
		return month + '/' + day + '/' + year;
	}
	
}
					